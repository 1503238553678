<template>
  <div v-if="canShow">
    <Tanchuang :path="path" :id="id" />
    <Kcz :path="path" :id="id" />
    <div class="tuji">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <img :src="item.src" class="pic" />
          <div class="pagination">
            <span class="current">{{ index + 1 }}</span>
            /
            <span class="total">{{ list.length }}</span>
          </div>
          <div class="tuji-title">
            {{ item.title }}
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <OpenApp2 :path="path" :id="id" />
  </div>
</template>

<script>
  import Tanchuang from "@/components/tanchuang";
  import Kcz from "@/components/kcz";
  import Tuijian from "@/components/tuijian";
  import OpenApp2 from "@/components/openApp2";
  import OpenApp from "@/components/openApp";
  import linkApp from "@/utils/linkApp";
  import { getArticleDetail } from "@/utils/api";

  import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
  import "swiper/swiper-bundle.css";
  import { wxShare } from "@/utils/wxShare";
  export default {
    name: "tuji",
    components: {
      Tanchuang,
      Kcz,
      Tuijian,
      OpenApp2,
      OpenApp,
      Swiper,
      SwiperSlide,
    },
    directives: {
      swiper: directive,
    },
    data() {
      return {
        canShow: false,
        path: "kczPhotosDetail",
        id: "",
        swiperOptions: {
          autoHeight: true,
        },
        list: [],
        share_config: {},
      };
    },
    methods: {
      linkApp() {
        const data = {
          path: this.path,
          param: this.id,
        };
        linkApp(data);
      },
      async getDetail(id) {
        const res = await getArticleDetail(id);
        const data = res.data.data;
        this.list = data.materials.gallery.map(val => {
          return {
            title: val.title,
            src: val.src,
          };
        });
        this.share_config = data.share_config;
        this.canShow = true;
        document.title = data.title;
        wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getDetail(this.id);
    },
  };
</script>

<style lang="scss">
  .tuji {
    background-color: #000;
    padding-bottom: 50px;
    min-height: calc(100vh - 50px);
    .swiper-container {
      width: 100%;
      .swiper-slide {
        width: 100%;
        min-height: calc(100vh-50px);
        .pic {
          width: 100%;
          height: auto;
        }
        .pagination {
          margin: 10px auto;
          width: 92%;
          font-size: 18px;
          font-weight: bold;
          color: #d6d6d8;
        }
        .tuji-title {
          margin: 10px auto;
          width: 92%;
          font-size: 16px;
          line-height: 1.5;
          color: #999999;
        }
      }
    }
  }
</style>
